import React from 'react'
import Slider from 'react-animated-slider'
import { Link } from 'react-router-dom'
import 'react-animated-slider/build/vertical.css'
import { slidersRotationSpeed } from '../../constants'

import './style.css'

const content = [
  { id: 1, image: '/_ima/Dev_Banner.png' },
  { id: 2, image: '/_ima/DevOps_banner-AltTxt.png' },
  { id: 3, image: '/_ima/Cloud_banner-AltTxt.png' },
]

const Home = () => (
  <div className='home'>
    <Slider
      direction='vertical'
      autoplay={slidersRotationSpeed}
      touchDisabled
      previousButton={null}
      nextButton={null}
    >
      {content.map(item => (
        <div
          key={item.id}
          style={{ backgroundImage: `url('${item.image}')` }}
        />
      ))}
    </Slider>
    <div className='content-wrapper'>
      <div id='index-left'>
        <div className='col-logo'>
          <Link to='/development'>
            <img src='/_ima/Development.png' alt='OpenStack Consulting' />
          </Link>
        </div>
        <p className='block-title'>
          <Link to='/development'>
            <b>Appko Development Services</b>
          </Link>
        </p>
        <p className='text-center '>
          <Link
            to='/development'
            className='btn btn-blue btn-main text-center'
          >
            Learn more
          </Link>
        </p>
      </div>
      <div id='index-mid'>
        <div className='col-logo'>
          <Link to='/development'>
            <img src='/_ima/DevOps.png' alt='VMware Professional Services' />
          </Link>
        </div>
        <p className='block-title'>
          <Link to='/development'>
            <b>Dev Operations and QA Services</b>
          </Link>
        </p>
        <p className='text-center'>
          <Link
            to='/development'
            className='btn btn-blue btn-main text-center'
          >
            Learn more
          </Link>
        </p>
      </div>
      <div id='index-right'>
        <div className='col-logo'>
          <Link to='/cloud'>
            <img src='/_ima/Cloud.png' alt='Cisco Cloud Advanced Services' />
          </Link>
        </div>
        <p className='block-title'>
          <Link to='/cloud'>
            <b>Appko Cloud Services</b>
          </Link>
        </p>
        <p className='text-center'>
          <Link
            to='/cloud'
            className='btn btn-blue btn-main text-center'
          >
            Learn more
          </Link>
        </p>
      </div>
    </div>
  </div>
)

export default Home
